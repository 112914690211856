import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    metricsList: {
      // eslint-disable
      users_amount: {
        requestType: 'users_amount', time_interval_types: ['cur', 'prev'], data_sets: ['main', 'alternative'], taskName: 'Passengers amount', customerType: ['airplane', 'airport'],
      },
      duration_on_map: {
        requestType: 'duration_on_map', time_interval_types: ['cur', 'prev'], data_sets: ['main'], taskName: 'Average time on map', customerType: ['airplane', 'airport'],
      },
      hashtag_rating: {
        requestType: 'hashtag_rating', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Hashtag rating', customerType: ['airplane', 'airport'],
      },
      hashtag_board: {
        requestType: 'hashtag_board', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Hashtag rating', customerType: ['airplane', 'airport'],
      },
      hashtag_map: {
        requestType: 'hashtag_map', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Hashtag rating', customerType: ['airplane', 'airport'],
      },
      hashtag_highlights: {
        requestType: 'hashtag_highlights', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Hashtag rating', customerType: ['airplane'],
      },
      poi_destination_rating: {
        requestType: 'poi_destination_rating', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'POIs destinations rating', customerType: ['airplane', 'airport'],
      },
      destination_rating: {
        requestType: 'destination_rating', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Destinations rating', customerType: ['airplane', 'airport'],
      },
      poi_rating: {
        requestType: 'poi_rating', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'POIs rating', customerType: ['airplane', 'airport'],
      },
      unique_users_date: {
        requestType: 'unique_users_date', time_interval_types: ['cur'], data_sets: ['main', 'alternative'], taskName: 'Unique users distribution', customerType: ['airplane', 'airport'],
      },
      feature_distribution: {
        requestType: 'feature_distribution', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Features views amount', customerType: ['airplane', 'airport'],
      },
      feature_distribution_autoplay: {
        requestType: 'feature_distribution_autoplay', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Features views amount (in autoplay)', customerType: ['airplane', 'airport'],
      },
      feature_distribution_not_autoplay: {
        requestType: 'feature_distribution_not_autoplay', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Features views amount (out of autoplay)', customerType: ['airplane', 'airport'],
      },
      video_data: {
        requestType: 'video_data', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Video amount and duration', customerType: ['airplane', 'airport'],
      },
      get_langs: {
        requestType: 'get_langs', time_interval_types: ['cur'], data_sets: ['main', 'alternative'], taskName: 'Langs', customerType: ['airplane', 'airport'],
      },
      get_devices: {
        requestType: 'get_devices', time_interval_types: ['cur'], data_sets: ['main', 'alternative'], taskName: 'Devices', customerType: ['airplane', 'airport'],
      },
      get_os: {
        requestType: 'get_os', time_interval_types: ['cur'], data_sets: ['main', 'alternative'], taskName: 'Operating systems', customerType: ['airplane', 'airport'],
      },
      get_browsers: {
        requestType: 'get_browsers', time_interval_types: ['cur'], data_sets: ['main', 'alternative'], taskName: 'Browsers', customerType: ['airplane', 'airport'],
      },
      get_routes: {
        requestType: 'get_routes', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Routes', customerType: ['airplane', 'airport'],
      },
      get_filters: {
        requestType: 'get_filters', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Filters', customerType: ['airplane', 'airport'],
      },
      get_route_filters: {
        requestType: 'get_route_filters', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Routes with filters', customerType: ['airplane', 'airport'],
      },
      routes_amount: {
        requestType: 'routes_amount', time_interval_types: ['cur', 'prev'], data_sets: ['main'], taskName: 'Routes amount', customerType: ['airplane', 'airport'],
      },
      poi_amount: {
        requestType: 'poi_amount', time_interval_types: ['cur', 'prev'], data_sets: ['main'], taskName: 'POIs views amount', customerType: ['airplane', 'airport'],
      },
      poi_amount_highlights: {
        requestType: 'poi_amount_highlights', time_interval_types: ['cur', 'prev'], data_sets: ['main'], taskName: 'POIs views amount', customerType: ['airplane'],
      },
      poi_amount_board: {
        requestType: 'poi_amount_board', time_interval_types: ['cur', 'prev'], data_sets: ['main'], taskName: 'POIs views amount', customerType: ['airplane', 'airport'],
      },
      poi_amount_map: {
        requestType: 'poi_amount_map', time_interval_types: ['cur', 'prev'], data_sets: ['main'], taskName: 'POIs views amount', customerType: ['airplane', 'airport'],
      },
      unique_users_date_particular: {
        requestType: 'unique_users_date', time_interval_types: ['cur'], data_sets: ['main', 'alternative'], taskName: 'Unique users amount particular', customerType: ['airplane', 'airport'],
      },
      get_tail_number_users: {
        requestType: 'get_tail_number_users', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Tail number unique users', customerType: ['airplane'],
      },
      get_flight_number_users: {
        requestType: 'get_flight_number_users', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Flight number unique users', customerType: ['airplane'],
      },
      get_flight_numbers_for_interval: {
        requestType: 'get_flight_numbers_for_interval', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Flight numbers number for a interval', customerType: ['airplane'],
      },
      get_tail_numbers_amount: {
        requestType: 'get_tail_numbers_amount', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Tail number amount', customerType: ['airplane'],
      },
      get_flight_numbers_amount: {
        requestType: 'get_flight_numbers_amount', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Flight number amount', customerType: ['airplane'],
      },
      get_flight_numbers_amount_date: {
        requestType: 'get_flight_numbers_amount_date', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Flight number date amount', customerType: ['airplane'],
      },
      get_tail_numbers_amount_date: {
        requestType: 'get_tail_numbers_amount_date', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Tail number date amount', customerType: ['airplane'],
      },
      tripbits_routemap: {
        requestType: 'tripbits_routemap', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Route map data', customerType: ['airport'],
      },
      route_destinations: {
        requestType: 'route_destinations', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Routes destinations', customerType: ['airplane'],
      },
      tails_numbers: {
        requestType: 'tails_numbers', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Tail numbers', customerType: ['airplane'],
      },
      poi_highlights: {
        requestType: 'poi_highlights', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Highlights', customerType: ['airplane'],
      },
      poi_pins: {
        requestType: 'poi_pins', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Map pins', customerType: ['airplane', 'airport'],
      },
      poi_board: {
        requestType: 'poi_board', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Board', customerType: ['airplane', 'airport'],
      },
      microsite_highlights: {
        requestType: 'microsite_highlights', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Microsite highlights', customerType: ['airplane'],
      },
      microsite_board: {
        requestType: 'microsite_board', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Microsite board', customerType: ['airplane', 'airport'],
      },
      microsite_show: {
        requestType: 'microsite_show', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Microsite show', customerType: ['airplane', 'airport'],
      },
      playlist_highlights: {
        requestType: 'playlist_highlights', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Playlists (hashtags) highlights', customerType: ['airplane'],
      },
      playlist_board: {
        requestType: 'playlist_board', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Playlists (hashtags) board click', customerType: ['airplane', 'airport'],
      },
      playlists_board: {
        requestType: 'playlists_board', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Playlists (hashtags) board show', customerType: ['airplane', 'airport'],
      },
      advertisement: {
        requestType: 'advertisement', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Advertisement show', customerType: ['airplane', 'airport'],
      },
      poi_info_page: {
        requestType: 'poi_info_page', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'POI info page', customerType: ['airplane', 'airport'],
      },
      // eslint-enable
    },

    extraParameters: {
      route_destinations: {
        requestType: 'route_destinations', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Routes destinations', customerType: ['airplane'],
      },
      tails_numbers: {
        requestType: 'tails_numbers', time_interval_types: ['cur'], data_sets: ['main'], taskName: 'Tail numbers', customerType: ['airplane'],
      },
    },

    devicesTypesMapping: {
      mobile: ['Mobile Phone', 'Mobile Device', 'Ebook Reader'],
      tablet: ['Tablet'],
      desktop: ['Desktop', 'n/a', 'unknown'],
    },

    activePagedItemNumber: {
      route: 0,
      destination: 0,
      filter: 0,
    },

    metricsData: {},

    staticMetricsData: {},

    routeDestinations: [],

    airportAppUrl: '',

    isCompositeCustomerHasAirport: false,

    isUseStaticMetricData: false,

    airplaneAlwaysMainDatasetSlugs: [
      'passenger_number',
      'passengers_three_months',
      'language_chart_short',
      'mobile_number_type_one',
      'tablet_number_type_one',
      'laptop_number_type_one',
      'active_passengers_chart',
      'language_paged_list',
      'mobile_number_type_two',
      'tablet_number_type_two',
      'laptop_number_type_two',
      'device_accordion',
      'operating_system_accordion',
      'browser_accordion',
    ],
  },

  getters: {
    // TODO: move to other storage
    airportAppUrl: (state) => state.airportAppUrl,

    isCompositeCustomerHasAirport: (state) => state.isCompositeCustomerHasAirport,

    metricsData: (state) => state.metricsData,

    metricsList: (state) => state.metricsList,

    extraParameters: (state) => state.extraParameters,

    metricBySlug: (state) => (slug) => state.metricsList[slug],

    isGlobalPercent: (state, getters, rootState) => rootState.controls.isGlobalPercent,

    activeDataset: (state, getters, rootState, rootGetters) => rootGetters['controls/activeDataset'],

    isShowTrueData: (state, getters, rootState, rootGetters) => rootGetters['controls/isShowTrueData'],

    activeDestinationNumber: (state) => state.activePagedItemNumber.destination,

    activeRouteNumber: (state) => state.activePagedItemNumber.route,

    activeFilterNumber: (state) => state.activePagedItemNumber.filter,

    conditionalDataset: (state, getters) => (metricSlug) => {
      // eslint-disable-next-line max-len

      if (state.airplaneAlwaysMainDatasetSlugs.includes(metricSlug)) {
        if (!state.isCompositeCustomerHasAirport || getters.isShowTrueData) {
          return 'main';
        }
        return 'alternative';
      }

      return getters.activeDataset;
    },

    metricDataMapping: (state, getters) => ({
      // static
      passenger_number: getters.infoDataPassengers,
      duration_on_map: getters.infoDataAvgTimeMap,
      places_viewed: getters.infoDataPoiAmount,
      places_viewed_native: getters.infoDataPoiAmount,
      routes_viewed: getters.infoDataRoutesAmount,

      // overview airplane
      passengers_three_months: getters.dataUsers,
      popular_destinations_short: getters.ratingsDataPopularDestinationsShort,
      popular_destinations_short_native: getters.ratingsDataPopularDestinationsShort,
      popular_places_short: getters.ratingsDataPopularPoiShort,
      popular_places_short_native: getters.ratingsDataPopularPoiShort,
      interests_chart_short: getters.interestsShort,
      interests_chart_short_native: getters.interestsShort,
      language_chart_short: getters.dataLanguages,
      mobile_number_type_one: getters.deviceNumberMobile,
      tablet_number_type_one: getters.deviceNumberTablet,
      laptop_number_type_one: getters.deviceNumberDesktop,

      // overview airport
      rm_airport_number: getters.airportRouteMapDataNumberOfAirports,
      rm_flights_per_month: getters.airportRouteMapDataFlightsPerMonth,
      ap_popular_destinations_short: getters.ratingsDataAirportPopularDestinationOverview,
      ap_popular_routes_short: getters.ratingsDataAirportPopularRoutesOverview,
      ap_popular_interests_short: getters.ratingsDataAirportPopularInterestsOverview,
      ap_tb_guides_number: getters.airportTripbitsDataNumberOfGuides,
      ap_tb_pois_number: getters.airportTripbitsDataNumberOfPoi,
      ap_tb_reels_number: getters.airportTripbitsDataNumberOfReels,

      // engagement
      active_passengers_chart: getters.usersByDate,
      feature_usage_chart: getters.featuresUsage,
      feature_autoplay_usage_chart: getters.featuresUsageAutoplay,
      feature_not_autoplay_usage_chart: getters.featuresUsageNotAutoplay,
      video_number_chart: getters.videoAmount,
      video_duration_chart: getters.videoDuration,

      // destinations
      popular_destinations_paged_list: getters.ratingsProgressDataDestinations,
      popular_destinations_paged_list_native: getters.ratingsProgressDataDestinations,
      popular_places_paged_list: getters.ratingsProgressDataPois,
      popular_places_paged_list_native: getters.ratingsProgressDataPois,
      popular_places_paged_list_no_highlights: getters.ratingsProgressDataPois,
      destination_relations_tabs: getters.unitedDestinationData,
      destination_relations_tabs_no_highlights: getters.unitedDestinationData,

      // new tab
      microsites_paged_list: getters.ratingsDataMicrositesFull,
      microsites_paged_list_no_highlights: getters.ratingsDataMicrositesFull,
      playlists_paged_list_no_highlights: getters.ratingsDataPlaylistsFull,
      playlists_paged_list: getters.ratingsDataPlaylistsFull,
      advertisement_paged_list: getters.ratingsDataAdvertisement,

      // routes
      routes_paged_list: getters.ratingsProgressDataRouteParams,
      filters_paged_list: getters.ratingsProgressDataFilters,
      filter_values_paged_list: getters.ratingsProgressDataSelections,

      // passengers
      interests_paged_list: getters.ratingsProgressDataPassengersInterests,
      interests_paged_list_native: getters.ratingsProgressDataPassengersInterests,
      language_paged_list: getters.ratingsProgressDataPassengersLanguages,

      // devices
      mobile_number_type_two: getters.deviceNumberMobile,
      tablet_number_type_two: getters.deviceNumberTablet,
      laptop_number_type_two: getters.deviceNumberDesktop,
      device_accordion: getters.ratingsProgressDataUserDevices,
      operating_system_accordion: getters.ratingsProgressDataUserOperatingSystems,
      browser_accordion: getters.ratingsProgressDataUserBrowsers,

      // tail numbers
      tail_numbers_paged_list: getters.ratingsProgressDataTailNumberUsers,
      tail_numbers_number: getters.infoDataTailNumbersAmount,
      tail_numbers_chart: getters.tailNumbers,

      // flight numbers
      flight_numbers_paged_list: getters.ratingsProgressDataFlightNumberUsers,
      flight_numbers_and_users_paged_list: getters.ratingsDataFlightNumbersAndUsersForInterval,
      flight_numbers_number: getters.infoDataFlightNumbersAmount,
      flight_numbers_chart: getters.flightNumbers,
    }),

    metricDataBySlug: (state, getters) => (slug) => {
      if (state.isUseStaticMetricData) {
        return state.staticMetricsData[slug];
      }

      return getters.metricDataMapping[slug](getters.conditionalDataset(slug));
    },

    routeDestinations: (state) => state.routeDestinations,

    getMetricDataValueByKey: (state) => ({ metricKey, metricsArray = null }) => {
      // eslint-disable-next-line no-param-reassign
      metricsArray = metricsArray === null ? state.metricsData : metricsArray;

      if (metricsArray[metricKey] === undefined || metricsArray[metricKey] == null) {
        return 0;
      }

      if (metricsArray[metricKey].data == null) {
        return 0;
      }

      return metricsArray[metricKey].data[0].value;
    },

    getMetricSpecificDataValueByKey: (state) => ({ metricKey, dataKey, metricsArray = null }) => {
      // eslint-disable-next-line no-param-reassign
      metricsArray = metricsArray === null ? state.metricsData : metricsArray;

      if (metricsArray[metricKey] === undefined || metricsArray[metricKey] == null) {
        return 0;
      }

      if (metricsArray[metricKey].data == null || metricsArray[metricKey].data.length === 0) {
        return 0;
      }

      return metricsArray[metricKey].data[dataKey];
    },

    getMetricDataArrayByKey: (state) => ({ metricKey, metricsArray = null }) => {
      // eslint-disable-next-line no-param-reassign
      metricsArray = metricsArray === null ? state.metricsData : metricsArray;

      if (metricsArray[metricKey] === undefined || metricsArray[metricKey] == null) {
        return [];
      }

      if (metricsArray[metricKey].data == null) {
        return [];
      }

      return [...metricsArray[metricKey].data];
    },

    getMetricDataObjectByKey: (state) => ({ metricKey, metricsArray = null }) => {
      // eslint-disable-next-line no-param-reassign
      metricsArray = metricsArray === null ? state.metricsData : metricsArray;

      if (metricsArray[metricKey] === undefined || metricsArray[metricKey] == null) {
        return {};
      }

      if (metricsArray[metricKey].data == null) {
        return {};
      }

      return metricsArray[metricKey].data;
    },

    getValueChangeInPercent: () => ({ value1, value2 }) => {
      let colorStyle = 'neutral';
      let signMark = '';
      let diffVal = 0;

      if (value1 !== null && value2 != null) {
        const diff = 100 * (value1 - value2);

        if (diff < 0) {
          colorStyle = 'negative';
        } else if (diff > 0) {
          colorStyle = 'positive';
          signMark = '+';
        }

        diffVal = value2 === 0
          ? `${(diff).toFixed(1)}%`
          : `${(diff / value2).toFixed(1)}%`;
      }

      return {
        value: `${signMark}${diffVal}`,
        style: colorStyle,
      };
    },

    // ---------------------------------------------------
    // METRICS START
    // ---------------------------------------------------

    infoDataPassengers: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const value1 = getters.getMetricDataValueByKey({ metricKey: `users_amount_cur_${selectedDataset}` });
      const value2 = getters.getMetricDataValueByKey({ metricKey: `users_amount_prev_${selectedDataset}` });

      const changes = getters.getValueChangeInPercent({ value2, value1 });

      const bounced = getters.getMetricDataValueByKey({ metricKey: 'users_amount_cur_main' })
      - getters.getMetricDataValueByKey({ metricKey: 'users_amount_cur_alternative' });

      return {
        value1,
        value2,
        changes,
        bounced,
      };
    },

    infoDataAvgTimeMap: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const value1 = getters.getMetricDataValueByKey({ metricKey: `duration_on_map_cur_${selectedDataset}` });
      const value2 = getters.getMetricDataValueByKey({ metricKey: `duration_on_map_prev_${selectedDataset}` });
      const changes = getters.getValueChangeInPercent({ value2, value1 });

      return {
        value1,
        value2,
        changes,
      };
    },

    infoDataRoutesAmount: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const value1 = getters.getMetricDataValueByKey({ metricKey: `routes_amount_cur_${selectedDataset}` });
      const value2 = getters.getMetricDataValueByKey({ metricKey: `routes_amount_prev_${selectedDataset}` });
      const changes = getters.getValueChangeInPercent({ value2, value1 });

      return {
        value1,
        value2,
        changes,
      };
    },

    poiNumberFull: (state, getters) => (period = 'cur', dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const clickValue = getters.getMetricDataValueByKey({ metricKey: `poi_amount_${period}_${selectedDataset}` });
      const mapValue = getters.getMetricDataValueByKey({ metricKey: `poi_amount_map_${period}_${selectedDataset}` });
      const boardValue = getters.getMetricDataValueByKey({ metricKey: `poi_amount_board_${period}_${selectedDataset}` });
      const highlightsValue = getters.getMetricDataValueByKey({ metricKey: `poi_amount_highlights_${period}_${selectedDataset}` });

      // eslint-disable-next-line max-len
      return [clickValue, mapValue, boardValue, highlightsValue].reduce((accum, cur) => accum + cur, 0);
    },

    infoDataPoiAmount: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const value1 = getters.poiNumberFull('cur', selectedDataset);
      const value2 = getters.poiNumberFull('prev', selectedDataset);
      const changes = getters.getValueChangeInPercent({ value2, value1 });

      return {
        value1,
        value2,
        changes,
      };
    },

    // overview
    ratingDataDestinations: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const destinations = getters.getMetricDataArrayByKey({ metricKey: `destination_rating_cur_${selectedDataset}` });
      const poiDestinations = getters.getMetricDataArrayByKey({ metricKey: `poi_destination_rating_cur_${selectedDataset}` });

      // eslint-disable-next-line no-restricted-syntax
      for (const poi of poiDestinations) {
        // eslint-disable-next-line no-restricted-syntax
        for (const geotag of poi.geotags) {
          const metricDestinationIndex = destinations.findIndex((el) => el.id === geotag.id);

          if (metricDestinationIndex === -1) {
            destinations.push({ ...geotag, ...{ value: poi.value } });
          } else {
            destinations[metricDestinationIndex].value += poi.value;
          }
        }
      }

      destinations.sort((a, b) => b.value - a.value);

      return destinations;
    },

    ratingsDataPopularDestinationsShort: (state, getters) => (dataset) => Vue
      .$arrayManagement.normalizedDataArray(
        getters.ratingsProgressDataDestinations(dataset).map(
          (el) => ({
            label: el.label,
            value: el.value,
          }),
        ).filter((el) => el.label !== '<All>'),
      ).slice(0, 3),

    ratingsDataPoi: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters
        .getMetricDataArrayByKey({ metricKey: `poi_destination_rating_cur_${selectedDataset}` })
        .sort((a, b) => a.value - b.value);
    },

    ratingsDataPoiFull: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const poiDestinations = getters.getMetricDataArrayByKey({ metricKey: `poi_destination_rating_cur_${selectedDataset}` });
      const poiHighlights = getters.getMetricDataArrayByKey({ metricKey: `poi_highlights_cur_${selectedDataset}` });
      const poiBoard = getters.getMetricDataArrayByKey({ metricKey: `poi_board_cur_${selectedDataset}` });
      const poiPins = getters.getMetricDataArrayByKey({ metricKey: `poi_pins_cur_${selectedDataset}` });
      const poiInfo = getters.getMetricDataArrayByKey({ metricKey: `poi_info_page_cur_${selectedDataset}` });

      const valuesArray = [
        { arrayItem: poiDestinations, label: 'click' },
        { arrayItem: poiHighlights, label: 'highlights' },
        { arrayItem: poiBoard, label: 'board' },
        { arrayItem: poiPins, label: 'pins' },
        { arrayItem: poiInfo, label: 'view_info' },
      ];

      const fullPois = [];

      valuesArray.forEach((valElement) => {
        valElement.arrayItem.forEach((el) => {
          if (fullPois[el.id] === undefined) {
            fullPois[el.id] = {
              name: el.name,
              id: el.id,
              geotags: el.geotags,
              value: 0,
              values: {
                total: 0,
                click: 0,
                highlights: 0,
                pins: 0,
                board: 0,
                view_info: 0,
              },
              links: {
                videos: el.videos,
              },
            };
          }

          fullPois[el.id].values[valElement.label] += el.value;
          fullPois[el.id].values.total += el.value;
          fullPois[el.id].value += el.value;
        });
      });

      return fullPois.sort((a, b) => b.value - a.value);
    },

    ratingsDataPopularPoiShort: (state, getters) => (dataset = null) => Vue
      .$arrayManagement.normalizedDataArray(
        getters.ratingsDataPoiFull(dataset)
          .slice(0, 3)
          .map(
            (el) => ({
              label: el.name,
              value: el.value,
            }),
          ),
      ),

    // eslint-disable-next-line max-len
    ratingsDataAirportPopularDestinationOverview: (state, getters) => (dataset) => Vue
      .$arrayManagement.normalizedDataArray(
        getters.ratingsProgressDataDestinations(dataset).map(
          (el) => ({
            label: el.label,
            value: el.value,
          }),
        ).filter((el) => el.label !== '<All>'),
      ).slice(0, 5),

    ratingsDataAirportPopularRoutesOverview: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const metric = getters.getMetricDataArrayByKey({ metricKey: `get_routes_cur_${selectedDataset}` });

      return Vue.$arrayManagement.normalizedDataArray(metric).slice(0, 5);
    },

    ratingsDataMicrositesFull: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const micrositesShow = getters.getMetricDataArrayByKey({ metricKey: `microsite_show_cur_${selectedDataset}` });
      const micrositesHighlights = getters.getMetricDataArrayByKey({ metricKey: `microsite_highlights_cur_${selectedDataset}` });
      const micrositesBoard = getters.getMetricDataArrayByKey({ metricKey: `microsite_board_cur_${selectedDataset}` });

      const valuesArray = [
        { arrayItem: micrositesShow, label: 'show' },
        { arrayItem: micrositesHighlights, label: 'highlights' },
        { arrayItem: micrositesBoard, label: 'board' },
      ];

      const fullMicrosites = [];

      valuesArray.forEach((valElement) => {
        valElement.arrayItem.forEach((el) => {
          if (fullMicrosites[el.id] === undefined) {
            fullMicrosites[el.id] = {
              label: el.label,
              id: el.id,
              value: 0,
              geotags: [{ label: '<All>', id: 0 }],
              values: {
                total: 0,
                show: 0,
                highlights: 0,
                board: 0,
              },
            };
          }

          fullMicrosites[el.id].values[valElement.label] += el.value;
          fullMicrosites[el.id].values.total += el.value;
          fullMicrosites[el.id].value += el.value;
        });
      });

      fullMicrosites.sort((a, b) => b.value - a.value);

      fullMicrosites.unshift({
        label: '<All>',
        id: 0,
        value: fullMicrosites.reduce((prev, cur) => prev + cur.value, 0),
        geotags: [{ label: '<All>', id: 0 }],
        values: {
          total: fullMicrosites.reduce((prev, cur) => prev + cur.values.total, 0),
          show: fullMicrosites.reduce((prev, cur) => prev + cur.values.show, 0),
          highlights: fullMicrosites.reduce((prev, cur) => prev + cur.values.highlights, 0),
          board: fullMicrosites.reduce((prev, cur) => prev + cur.values.board, 0),
        },
      });

      return fullMicrosites;
    },

    ratingsDataMicrositesFullDestinations: (state, getters) => (dataset) => {
      const ratingsProgressDataDestinations = getters.ratingsProgressDataDestinations(dataset);
      const ratingsDataMicrositesFull = getters.ratingsDataMicrositesFull(dataset)
        .filter((el) => el.label !== '<All>');

      const metric = [];

      ratingsProgressDataDestinations.forEach((destinationItem) => {
        const micrositeItem = {
          label: destinationItem.label,
          id: destinationItem.id,
        };

        micrositeItem.microsites = ratingsDataMicrositesFull
          .filter((el) => el.geotags.map((gel) => gel.id).includes(destinationItem.id));

        micrositeItem.microsites.unshift({
          label: '<All>',
          id: 0,
          value: micrositeItem.microsites.reduce((accum, cur) => accum + cur.value, 0),
          values: {
            total: micrositeItem.microsites.reduce((prev, cur) => prev + cur.values.total, 0),
            show: micrositeItem.microsites.reduce((prev, cur) => prev + cur.values.show, 0),
            // eslint-disable-next-line max-len
            highlights: micrositeItem.microsites.reduce((prev, cur) => prev + cur.values.highlights, 0),
            board: micrositeItem.microsites.reduce((prev, cur) => prev + cur.values.board, 0),
          },
        });

        metric.push(micrositeItem);
      });

      return metric;
    },

    ratingsDataPlaylistsFull: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const playlistsBoardClick = getters.getMetricDataArrayByKey({ metricKey: `playlist_board_cur_${selectedDataset}` });
      const playlistsBoardShow = getters.getMetricDataArrayByKey({ metricKey: `playlists_board_cur_${selectedDataset}` });
      const playlistsHighlights = getters.getMetricDataArrayByKey({ metricKey: `playlist_highlights_cur_${selectedDataset}` });

      const valuesArray = [
        { arrayItem: playlistsBoardShow, label: 'board_show' },
        { arrayItem: playlistsBoardClick, label: 'board_click' },
        { arrayItem: playlistsHighlights, label: 'highlights' },
      ];

      const fullPlaylists = [];

      valuesArray.forEach((valElement) => {
        valElement.arrayItem.forEach((el) => {
          if (fullPlaylists[el.id] === undefined) {
            fullPlaylists[el.id] = {
              label: el.label,
              id: el.id,
              value: 0,
              geotags: [{ label: '<All>', id: 0 }, ...el.geotags.map((elGeotag) => ({ label: elGeotag.label, id: elGeotag.id }))],
              values: {
                total: 0,
                board_show: 0,
                board_click: 0,
                highlights: 0,
              },
            };
          }

          fullPlaylists[el.id].values[valElement.label] += el.value;
          fullPlaylists[el.id].values.total += el.value;
          fullPlaylists[el.id].value += el.value;
        });
      });

      fullPlaylists.sort((a, b) => b.value - a.value);

      fullPlaylists.unshift({
        label: '<All>',
        id: 0,
        value: fullPlaylists.reduce((prev, cur) => prev + cur.value, 0),
        geotags: [{ label: '<All>', id: 0 }],
        values: {
          total: fullPlaylists.reduce((prev, cur) => prev + cur.values.total, 0),
          board_show: fullPlaylists.reduce((prev, cur) => prev + cur.values.board_show, 0),
          board_click: fullPlaylists.reduce((prev, cur) => prev + cur.values.board_click, 0),
          highlights: fullPlaylists.reduce((prev, cur) => prev + cur.values.highlights, 0),
        },
      });

      return fullPlaylists;
    },

    ratingsDataPlaylistsFullDestinations: (state, getters) => (dataset) => {
      const ratingsProgressDataDestinations = getters.ratingsProgressDataDestinations(dataset);
      const ratingsDataPlaylistsFull = getters.ratingsDataPlaylistsFull(dataset)
        .filter((el) => el.label !== '<All>');

      const metric = [];

      ratingsProgressDataDestinations.forEach((destinationItem) => {
        const playlistItem = {
          label: destinationItem.label,
          id: destinationItem.id,
        };

        playlistItem.playlists = ratingsDataPlaylistsFull
          .filter((el) => el.geotags.map((gel) => gel.id).includes(destinationItem.id));

        playlistItem.playlists.unshift({
          label: '<All>',
          id: 0,
          value: playlistItem.playlists.reduce((accum, cur) => accum + cur.value, 0),
          values: {
            total: playlistItem.playlists.reduce((prev, cur) => prev + cur.values.total, 0),
            // eslint-disable-next-line max-len
            board_show: playlistItem.playlists.reduce((prev, cur) => prev + cur.values.board_show, 0),
            // eslint-disable-next-line max-len
            board_click: playlistItem.playlists.reduce((prev, cur) => prev + cur.values.board_click, 0),
            // eslint-disable-next-line max-len
            highlights: playlistItem.playlists.reduce((prev, cur) => prev + cur.values.highlights, 0),
          },
        });

        metric.push(playlistItem);
      });

      return metric;
    },

    ratingsDataAdvertisement: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const metric = getters.getMetricDataArrayByKey({ metricKey: `advertisement_cur_${selectedDataset}` });

      const metricWithGeotags = metric.map((el) => ({ ...el, geotags: [{ label: '<All>', id: 0 }] }));
      metricWithGeotags.sort((a, b) => b.value - a.value);

      metricWithGeotags.unshift({
        label: '<All>',
        id: 0,
        geotags: [{ label: '<All>', id: 0 }],
        value: metricWithGeotags.reduce((prev, cur) => prev + cur.value, 0),
      });

      return metricWithGeotags;
    },

    ratingsDataAdvertisementDestinations: (state, getters) => (dataset) => {
      const ratingsProgressDataDestinations = getters.ratingsProgressDataDestinations(dataset);
      const ratingsDataAdvertisement = getters.ratingsDataAdvertisement(dataset)
        .filter((el) => el.label !== '<All>');

      const metric = [];

      ratingsProgressDataDestinations.forEach((destinationItem) => {
        const advertisementItem = {
          label: destinationItem.label,
          id: destinationItem.id,
        };

        advertisementItem.advertisements = ratingsDataAdvertisement
          .filter((el) => el.geotags.map((gel) => gel.id).includes(destinationItem.id));

        advertisementItem.advertisements.unshift({
          label: '<All>',
          id: 0,
          value: advertisementItem.advertisements.reduce((accum, cur) => accum + cur.value, 0),
        });

        metric.push(advertisementItem);
      });

      return metric;
    },

    interests: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const interestsClick = getters.getMetricDataArrayByKey({ metricKey: `hashtag_rating_cur_${selectedDataset}` });
      const interestsMap = getters.getMetricDataArrayByKey({ metricKey: `hashtag_map_cur_${selectedDataset}` });
      const interestsBoard = getters.getMetricDataArrayByKey({ metricKey: `hashtag_board_cur_${selectedDataset}` });
      const interestsHighlights = getters.getMetricDataArrayByKey({ metricKey: `hashtag_highlights_cur_${selectedDataset}` });

      const interests = {};

      // eslint-disable-next-line max-len
      [...interestsClick, ...interestsMap, ...interestsBoard, ...interestsHighlights].forEach((el) => {
        if (interests[el.label] === undefined) {
          interests[el.label] = { label: el.label, value: 0 };
        }

        interests[el.label].value += el.value;
      });

      const interestsArray = Object.values(interests);

      interestsArray.sort((a, b) => b.value - a.value);

      return interestsArray;
    },

    ratingsDataPoiInfo: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const metric = getters.getMetricDataArrayByKey({ metricKey: `poi_info_page_cur_${selectedDataset}` });

      const poiInfoFull = [];

      metric.forEach((el) => {
        if (poiInfoFull[el.id] === undefined) {
          poiInfoFull[el.id] = {
            label: el.name,
            id: el.id,
            value: 0,
            geotags: [{ label: '<All>', id: 0 }, ...el.geotags.map((elGeotag) => ({ label: elGeotag.label, id: elGeotag.id }))],
          };
        }

        poiInfoFull[el.id].value += el.value;
      });

      poiInfoFull.sort((a, b) => b.value - a.value);

      poiInfoFull.unshift({
        label: '<All>',
        id: 0,
        value: poiInfoFull.reduce((prev, cur) => prev + cur.value, 0),
        geotags: [{ label: '<All>', id: 0 }],
      });

      return poiInfoFull;
    },

    ratingsDataPoiInfoDestinations: (state, getters) => (dataset) => {
      const ratingsProgressDataDestinations = getters.ratingsProgressDataDestinations(dataset);
      const ratingsDataPoiInfo = getters.ratingsDataPoiInfo(dataset)
        .filter((el) => el.label !== '<All>');

      const metric = [];

      ratingsProgressDataDestinations.forEach((destinationItem) => {
        const poiInfoItem = {
          label: destinationItem.label,
          id: destinationItem.id,
        };

        poiInfoItem.poiInfoPages = ratingsDataPoiInfo
          .filter((el) => el.geotags.map((gel) => gel.id).includes(destinationItem.id));

        poiInfoItem.poiInfoPages.unshift({
          label: '<All>',
          id: 0,
          value: poiInfoItem.poiInfoPages.reduce((accum, cur) => accum + cur.value, 0),
        });

        metric.push(poiInfoItem);
      });

      return metric;
    },

    ratingsDataAirportPopularInterestsOverview: (state, getters) => (dataset) => {
      const metric = getters.interests(dataset);

      return Vue.$arrayManagement.normalizedDataArray(metric).slice(0, 5);
    },

    interestsShort: (state, getters) => (dataset) => {
      const metric = getters.interests(dataset);

      return metric.slice(0, 3);
    },

    // chartsDataCountriesShort: (state, getters) => (dataset = null) => {
    // const selectedDataset = dataset === null ? getters.activeDataset : dataset;
    //
    // return getters.getMetricDataArrayByKey(
    // `get_countries_cur_${selectedDataset}`
    // );
    // },

    dataLanguages: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const metric = getters.getMetricDataArrayByKey({ metricKey: `get_langs_cur_${selectedDataset}` });

      return metric.slice(0, 3);
    },

    dataUsers: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataArrayByKey({ metricKey: `unique_users_date_particular_cur_${selectedDataset}` });
    },

    ratingsProgressDataTailNumberUsers: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const metric = getters.getMetricDataArrayByKey({ metricKey: `get_tail_number_users_cur_${selectedDataset}` });

      return Vue.$arrayManagement.normalizedDataArray(metric);
    },

    ratingsProgressDataFlightNumberUsers: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const metric = getters.getMetricDataArrayByKey({ metricKey: `get_flight_number_users_cur_${selectedDataset}` });

      return Vue.$arrayManagement.normalizedDataArray(metric);
    },

    ratingsProgressDataFlightNumberForInterval: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const metric = getters.getMetricDataArrayByKey({ metricKey: `get_flight_numbers_for_interval_cur_${selectedDataset}` });

      return Vue.$arrayManagement.normalizedDataArray(metric);
    },

    ratingsDataFlightNumbersAndUsersForInterval: (state, getters) => (dataset) => {
      const users = getters.ratingsProgressDataFlightNumberUsers(dataset);
      const flights = getters.ratingsProgressDataFlightNumberForInterval(dataset);

      const metric = [];

      users.forEach((el) => {
        metric[el.label] = {
          label: el.label,
          values: {
            flights: flights.find((fl) => fl.label === el.label).value,
            passengers: el.value,
          },
        };
      });

      const metricArray = Object.values(metric);
      metricArray.sort((a, b) => b.values.passengers - a.values.passengers);

      return metricArray;
    },

    infoDataTailNumbersAmount: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataValueByKey({ metricKey: `get_tail_numbers_amount_cur_${selectedDataset}` });
    },

    infoDataFlightNumbersAmount: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataValueByKey({ metricKey: `get_flight_numbers_amount_cur_${selectedDataset}` });
    },

    tailNumbers: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataArrayByKey({ metricKey: `get_tail_numbers_amount_date_cur_${selectedDataset}` });
    },

    flightNumbers: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataArrayByKey({ metricKey: `get_flight_numbers_amount_date_cur_${selectedDataset}` });
    },

    // Engagement
    usersByDate: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataArrayByKey({ metricKey: `unique_users_date_cur_${selectedDataset}` });
    },

    featuresUsage: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataArrayByKey({ metricKey: `feature_distribution_cur_${selectedDataset}` });
    },

    featuresUsageAutoplay: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataArrayByKey({ metricKey: `feature_distribution_autoplay_cur_${selectedDataset}` });
    },

    featuresUsageNotAutoplay: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataArrayByKey({ metricKey: `feature_distribution_not_autoplay_cur_${selectedDataset}` });
    },

    videoAmount: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const metric = getters.getMetricDataArrayByKey({ metricKey: `video_data_cur_${selectedDataset}` });

      return metric.map(
        (el) => ({ label: el.label, value: el.value_number }),
      );
    },

    videoDuration: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const metric = getters.getMetricDataArrayByKey({ metricKey: `video_data_cur_${selectedDataset}` });

      return metric.map(
        // eslint-disable-next-line max-len
        (el) => ({ label: el.label, value: el.value_duration }),
      );
    },

    maxValueVideoDuration: (state, getters) => (dataset) => getters.videoDuration(dataset).reduce(
      (accum, cur) => (cur.value > accum ? cur.value : accum),
      0,
    ),

    videoDurationUnits: (state, getters) => (dataset) => {
      if (getters.maxValueVideoDuration(dataset) <= 100 * 60) {
        return 'min:sec';
      }

      return 'h:min';
    },

    // destinations
    ratingsProgressDataDestinations: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const destinations = getters.getMetricDataArrayByKey({ metricKey: `destination_rating_cur_${selectedDataset}` });
      const poiDestinations = getters.ratingsDataPoiFull(dataset);

      const allPois = [];

      const ratingsDataDestinations = [];

      poiDestinations.forEach((poiItem) => {
        const featuredPoi = {
          label: poiItem.name,
          id: poiItem.id,
          value: poiItem.value,
          values: poiItem.values,
          links: poiItem.links,
        };
        allPois.push(featuredPoi);

        if (poiItem.geotags) {
          poiItem.geotags.forEach((geotagItem) => {
            const geotagIndex = ratingsDataDestinations.findIndex((el) => el.id === geotagItem.id);

            if (geotagIndex === -1) {
              ratingsDataDestinations.push({
                label: geotagItem.name,
                id: geotagItem.id,
                value: poiItem.value,
                pois: [
                  {
                    label: '<All>',
                    id: 0,
                    value: poiItem.value,
                    values: {
                      total: 0,
                      click: 0,
                      highlights: 0,
                      pins: 0,
                      board: 0,
                      view_info: 0,
                    },
                    links: {
                      videos: [],
                    },
                  },
                  featuredPoi],
              });
            } else {
              ratingsDataDestinations[geotagIndex].value += poiItem.value;
              ratingsDataDestinations[geotagIndex].pois[0].value += poiItem.value;
              ratingsDataDestinations[geotagIndex].pois[0].values.total += poiItem.values.total;
              ratingsDataDestinations[geotagIndex].pois[0].values.click += poiItem.values.click;
              // eslint-disable-next-line max-len
              ratingsDataDestinations[geotagIndex].pois[0].values.highlights += poiItem.values.highlights;
              ratingsDataDestinations[geotagIndex].pois[0].values.pins += poiItem.values.pins;
              ratingsDataDestinations[geotagIndex].pois[0].values.board += poiItem.values.board;
              // eslint-disable-next-line max-len
              ratingsDataDestinations[geotagIndex].pois[0].values.view_info += poiItem.values.view_info;
              ratingsDataDestinations[geotagIndex].pois.push(featuredPoi);
            }
          });
        }
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const destinationItem of destinations) {
        const ratingsDataDestinationsIndex = ratingsDataDestinations
          .findIndex((el) => el.id === destinationItem.id);

        if (ratingsDataDestinationsIndex === -1) {
          ratingsDataDestinations.push({
            label: destinationItem.label,
            id: destinationItem.id,
            value: destinationItem.value,
            pois: [
              {
                label: '<All>',
                id: 0,
                value: 0,
                values: {
                  total: 0,
                  click: 0,
                  highlights: 0,
                  pins: 0,
                  board: 0,
                  view_info: 0,
                },
                links: {
                  videos: [],
                },
              }],
          });
        } else {
          ratingsDataDestinations[ratingsDataDestinationsIndex].value += destinationItem.value;
        }
      }

      const allPoisAmount = allPois
        .reduce((accum, cur) => accum + cur.value, 0);

      const allPoiValues = allPois
        .reduce((accum, cur) => ({
          total: accum.total + cur.values.total,
          click: accum.click + cur.values.click,
          highlights: accum.highlights + cur.values.highlights,
          pins: accum.pins + cur.values.pins,
          board: accum.board + cur.values.board,
          view_info: accum.view_info + cur.values.view_info,
        }), {
          total: 0,
          click: 0,
          highlights: 0,
          pins: 0,
          board: 0,
          view_info: 0,
        });

      const allGeotagsAmount = ratingsDataDestinations
        .reduce((accum, cur) => accum + cur.value, 0);

      ratingsDataDestinations.unshift({
        label: '<All>',
        id: 0,
        value: allGeotagsAmount,
        pois: [{
          label: '<All>',
          id: 0,
          value: allPoisAmount,
          values: allPoiValues,
          links: { videos: [] },
        }, ...allPois.sort((a, b) => b.value - a.value)],
      });

      ratingsDataDestinations.sort((a, b) => b.value - a.value);

      return ratingsDataDestinations;
    },

    ratingsProgressDataPois: (state, getters) => (dataset) => (
      getters.ratingsProgressDataDestinations(dataset)[
        getters.activeDestinationNumber
      ]
        ? [...getters.ratingsProgressDataDestinations(dataset)[
          getters.activeDestinationNumber
        ].pois]
        : []),

    unitedDestinationData: (state, getters) => (dataset) => {
      const popularPlaces = getters.ratingsProgressDataDestinations(dataset)[
        getters.activeDestinationNumber
      ]
        ? [...getters.ratingsProgressDataDestinations(dataset)[
          getters.activeDestinationNumber
        ].pois]
        : [];

      const advertisement = getters.ratingsDataAdvertisementDestinations(dataset)[
        getters.activeDestinationNumber
      ]
        ? [...getters.ratingsDataAdvertisementDestinations(dataset)[
          getters.activeDestinationNumber
        ].advertisements]
        : [];

      const microsites = getters.ratingsDataMicrositesFullDestinations(dataset)[
        getters.activeDestinationNumber
      ]
        ? [...getters.ratingsDataMicrositesFullDestinations(dataset)[
          getters.activeDestinationNumber
        ].microsites]
        : [];

      const playlists = getters.ratingsDataPlaylistsFullDestinations(dataset)[
        getters.activeDestinationNumber
      ]
        ? [...getters.ratingsDataPlaylistsFullDestinations(dataset)[
          getters.activeDestinationNumber
        ].playlists]
        : [];

      return {
        popular_places: popularPlaces,
        advertisement,
        microsites,
        playlists,
      };
    },

    // passengers
    ratingsProgressDataPassengersInterests: (state, getters) => (dataset) => {
      const metric = getters.interests(dataset);

      return Vue.$arrayManagement.normalizedDataArray(metric);
    },

    // ratingsProgressDataPassengersCountries: (state, getters) => {
    // const metric = getters.getMetricDataArrayByKey(
    // `get_countries_cur_${getters.activeDataset}`
    // );
    //
    //   return Vue.$arrayManagement.normalizedDataArray(metric);
    // },

    ratingsProgressDataPassengersLanguages: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const metric = getters.getMetricDataArrayByKey({ metricKey: `get_langs_cur_${selectedDataset}` });

      return Vue.$arrayManagement.normalizedDataArray(metric);
    },

    ratingsProgressDataUserDevices: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataArrayByKey({ metricKey: `get_devices_cur_${selectedDataset}` });
    },

    deviceNumberMobile: (state, getters) => (dataset) => {
      const deviceMetric = getters.ratingsProgressDataUserDevices(dataset);

      const typeNumber = {
        number: 0,
        total: 0,
      };

      deviceMetric.forEach(
        (item) => {
          if (state.devicesTypesMapping.mobile.includes(item.label)) {
            typeNumber.number += item.value;
          }

          typeNumber.total += item.value;
        },
      );

      return typeNumber.total
        ? ((typeNumber.number * 100) / typeNumber.total).toFixed(0)
        : 0;
    },

    deviceNumberTablet: (state, getters) => (dataset) => {
      const deviceMetric = getters.ratingsProgressDataUserDevices(dataset);

      const typeNumber = {
        number: 0,
        total: 0,
      };

      deviceMetric.forEach(
        (item) => {
          if (state.devicesTypesMapping.tablet.includes(item.label)) {
            typeNumber.number += item.value;
          }

          typeNumber.total += item.value;
        },
      );

      return typeNumber.total
        ? ((typeNumber.number * 100) / typeNumber.total).toFixed(0)
        : 0;
    },

    deviceNumberDesktop: (state, getters) => (dataset) => {
      const deviceMetric = getters.ratingsProgressDataUserDevices(dataset);

      const typeNumber = {
        number: 0,
        total: 0,
      };

      deviceMetric.forEach(
        (item) => {
          if (
            !state.devicesTypesMapping.mobile.includes(item.label)
              && !state.devicesTypesMapping.tablet.includes(item.label)
          ) {
            typeNumber.number += item.value;
          }

          typeNumber.total += item.value;
        },
      );

      return typeNumber.total
        ? ((typeNumber.number * 100) / typeNumber.total).toFixed(0)
        : 0;
    },

    ratingsProgressDataUserOperatingSystems: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataArrayByKey({ metricKey: `get_os_cur_${selectedDataset}` });
    },

    ratingsProgressDataUserBrowsers: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataArrayByKey({ metricKey: `get_browsers_cur_${selectedDataset}` });
    },

    // routes
    dataRoutesParamsFilters: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricDataObjectByKey({ metricKey: `get_route_filters_cur_${selectedDataset}` });
    },

    ratingsProgressDataRoutes: (state, getters) => (dataset) => {
      const metric = getters.dataRoutesParamsFilters(dataset);

      if (metric.routes === undefined || metric.routes.length === 0) {
        return [];
      }

      if (metric.routes[0].label !== '<All>') {
        metric.routes.unshift({
          label: '<All>',
          id: 0,
          value: metric.routes.reduce((prev, cur) => prev + cur.value, 0),
        });
      }

      return metric.routes;
    },

    ratingsProgressDataRouteParams: (state, getters) => (dataset) => {
      const metric = getters.dataRoutesParamsFilters(dataset);
      const parameters = metric.parameters === undefined || metric.parameters.length === 0
        ? []
        : metric.parameters;

      if (metric.routes === undefined || metric.routes.length === 0) {
        return [];
      }

      const fullRoutes = [];

      metric.routes.forEach((routeItem) => {
        if (fullRoutes[routeItem.label] === undefined) {
          fullRoutes[routeItem.label] = {
            label: routeItem.label,
            value: 0,
            values: {
              route_build: 0,
              list_to_map: 0,
              date_selected: 0,
              flights_viewed: 0,
              flight_details: 0,
              bookings: 0,
            },
          };

          fullRoutes[routeItem.label].values.route_build += routeItem.value;
          fullRoutes[routeItem.label].value += routeItem.value;

          if (parameters[routeItem.label] !== undefined) {
            Object.keys(parameters[routeItem.label]).forEach((paramKey) => {
              fullRoutes[routeItem.label].values[paramKey] += parameters[routeItem.label][paramKey];
              fullRoutes[routeItem.label].value += parameters[routeItem.label][paramKey];
            });
          }
        }
      });

      const fullRoutesArray = Object.values(fullRoutes);

      if (fullRoutesArray.findIndex((el) => el.label === '<All>') === -1) {
        const allRoutesNumber = fullRoutesArray
          .reduce((accum, cur) => accum + cur.value, 0);

        const allParametersValues = fullRoutesArray
          .reduce((accum, cur) => ({
            route_build: accum.route_build + cur.values.route_build,
            list_to_map: accum.list_to_map + cur.values.list_to_map,
            date_selected: accum.date_selected + cur.values.date_selected,
            flights_viewed: accum.flights_viewed + cur.values.flights_viewed,
            flight_details: accum.flight_details + cur.values.flight_details,
            bookings: accum.bookings + cur.values.bookings,
          }), {
            route_build: 0,
            list_to_map: 0,
            date_selected: 0,
            flights_viewed: 0,
            flight_details: 0,
            bookings: 0,
          });

        fullRoutesArray.unshift({
          label: '<All>',
          value: allRoutesNumber,
          values: allParametersValues,
        });
      }

      return fullRoutesArray.sort((a, b) => b.value - a.value);
    },

    ratingsProgressDataFilters: (state, getters) => (dataset) => {
      const metric = getters.dataRoutesParamsFilters(dataset);

      if (metric.filters === undefined || metric.filters.length === 0) {
        return [];
      }

      // eslint-disable-next-line max-len
      const filterRoute = getters.ratingsProgressDataRouteParams(dataset)[getters.activeRouteNumber].label;

      return metric.filters[filterRoute] !== undefined ? metric.filters[filterRoute] : [];
    },

    // eslint-disable-next-line max-len
    activeRouteName: (state, getters) => (dataset) => (getters.ratingsProgressDataRouteParams(dataset)[getters.activeRouteNumber]
      ? `Filters for route: ${getters.ratingsProgressDataRouteParams(dataset)[getters.activeRouteNumber].label}`
      : 'Filters'),

    // eslint-disable-next-line max-len
    activeRouteFilterName: (state, getters) => (dataset) => (getters.ratingsProgressDataFilters(dataset)[getters.activeFilterNumber]
      ? `Filter Value: ${getters.ratingsProgressDataFilters(dataset)[getters.activeFilterNumber].label}`
      : 'Filter Value'),

    // eslint-disable-next-line max-len
    ratingsProgressDataSelections: (state, getters) => (dataset) => (getters.ratingsProgressDataFilters(dataset)[getters.activeFilterNumber]
      ? [...getters.ratingsProgressDataFilters(dataset)[getters.activeFilterNumber].values]
      : []),

    airportRouteMapDataNumberOfAirports: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricSpecificDataValueByKey({
        metricKey: `tripbits_routemap_cur_${selectedDataset}`,
        dataKey: 'airports_amount',
      });
    },

    airportRouteMapDataFlightsPerMonth: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricSpecificDataValueByKey({
        metricKey: `tripbits_routemap_cur_${selectedDataset}`,
        dataKey: 'flights_per_month',
      });
    },

    airportTripbitsDataNumberOfGuides: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricSpecificDataValueByKey({
        metricKey: `tripbits_routemap_cur_${selectedDataset}`,
        dataKey: 'geotags_total_amount',
      });
    },

    airportTripbitsDataNumberOfPoi: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricSpecificDataValueByKey({
        metricKey: `tripbits_routemap_cur_${selectedDataset}`,
        dataKey: 'pois_amount',
      });
    },

    airportTripbitsDataNumberOfReels: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return getters.getMetricSpecificDataValueByKey({
        metricKey: `tripbits_routemap_cur_${selectedDataset}`,
        dataKey: 'videos_amount',
      });
    },

    routeDestinationsFilter: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      const metric = getters.getMetricDataArrayByKey({
        metricKey: `route_destinations_cur_${selectedDataset}`,
        metricsArray: getters.routeDestinations,
      });

      return [{ caption: 'empty', id: null }, ...metric.map((el) => ({ caption: el.label, id: el.label }))];
    },

    tailNumbersFilter: (state, getters) => (dataset = null) => {
      const selectedDataset = dataset === null ? getters.activeDataset : dataset;

      return [{ caption: 'empty', id: null, plug: selectedDataset }];
    },
    // metrics end

  },

  mutations: {
    SET_AIRPORT_APP_URL(state, value) {
      state.airportAppUrl = value;
    },

    SET_IS_COMPOSITE_CUSTOMER_HAS_AIRPORT(state, value) {
      state.isCompositeCustomerHasAirport = value;
    },

    CLEAR_METRICS_DATA(state) {
      state.metricsData = {};
    },

    SET_METRICS_DATA(state, metricsData) {
      state.metricsData = metricsData;
    },

    SET_STATIC_METRICS_DATA(state, metricsData) {
      state.staticMetricsData = metricsData;
    },

    SET_IS_USE_STATIC_METRICS_DATA(state, value) {
      state.isUseStaticMetricData = value;
    },

    SET_METRIC_DATA(state, { metricKey, metricData }) {
      state.metricsData[metricKey] = metricData;
    },

    SET_ACTIVE_DESTINATION(state, destinationNumber) {
      state.activePagedItemNumber.destination = destinationNumber;
    },

    SET_ACTIVE_ROUTE(state, routeNumber) {
      state.activePagedItemNumber.route = routeNumber;
    },

    SET_ACTIVE_FILTER(state, filterNumber) {
      state.activePagedItemNumber.filter = filterNumber;
    },

    SET_ROUTE_DESTINATIONS(state, routeDestinations) {
      state.routeDestinations = routeDestinations;
    },
  },

  actions: {
    setAirportAppUrl({ commit }, value) {
      commit('SET_AIRPORT_APP_URL', value);
    },

    setIsCompositeCustomerHasAirport({ commit }, value) {
      commit('SET_IS_COMPOSITE_CUSTOMER_HAS_AIRPORT', value);
    },

    clearMetricsData({ commit }) {
      commit('CLEAR_METRICS_DATA');
    },

    setMetricData({ commit }, { metricKey, metricData }) {
      commit('SET_METRIC_DATA', { metricKey, metricData });
    },

    setMetricsData({ commit }, metricsData) {
      commit('SET_METRICS_DATA', metricsData);
    },

    setStaticMetricsData({ commit }, metricsData) {
      commit('SET_STATIC_METRICS_DATA', metricsData);
    },

    setIsUseStaticMetricsData({ commit }, value) {
      commit('SET_IS_USE_STATIC_METRICS_DATA', value);
    },

    setActiveDestination({ commit }, destinationNumber) {
      commit('SET_ACTIVE_DESTINATION', destinationNumber);
    },

    setActiveRoute({ commit }, routeNumber) {
      commit('SET_ACTIVE_ROUTE', routeNumber);
    },

    setActiveFilter({ commit }, filterNumber) {
      commit('SET_ACTIVE_FILTER', filterNumber);
    },

    setRouteDestinations({ commit }, routeDestinations) {
      commit('SET_ROUTE_DESTINATIONS', routeDestinations);
    },
  },
};
